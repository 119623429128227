import React, { useState ,useEffect} from 'react';



import slid1 from "../assets/images/slids/Group 21.png"
import slid2 from "../assets/images/slids/image 8.png"
import slid3 from "../assets/images/slids/image 9.png"
import slid4 from "../assets/images/slids/image 10.png"
import slid5 from "../assets/images/slids/image 11.png"
import slid6 from "../assets/images/slids/image 12.png"





function Ico() {
 
    const divStyle = {
       width:"300px",
        // backgroundColor: 'lightgray',
     
      };

const [imageSrc, setImageSrc] = useState(slid1);
  
// An array of image paths
const imagePaths = [slid1, slid2,slid3];
var imgsr=slid1; 
// Function to update the image source
var b= 1 ;

const updateImageSrc = () => {
  // Choose a random image path from the array
//   const randomIndex = Math.floor(Math.random() * imagePaths.length);
//   const randomImagePath = imagePaths[randomIndex];
if(b==6){
    b=0;
}  
if(b == 0){
    setImageSrc(slid1);

}

if(b == 1){
    setImageSrc(slid2);

}
if(b == 2){
    setImageSrc(slid3);
 

}
if(b == 3){
    setImageSrc(slid4);

}

if(b == 4){
    setImageSrc(slid5);

}
if(b == 5){
    setImageSrc(slid6);
 

}
b++;

    
};
  
// useEffect hook to update the image source every one second
useEffect(() => {
    setInterval(function slids(){
    // alert("hi")
    updateImageSrc();
  }, 5050);
//   return () => clearInterval(interval);
}, []); // Empty dependency array ensures that this effect runs only once on component mount
   
  return (
    
    <img src={imageSrc} alt="" />
  );
}

export default Ico;