import React, { useState ,useEffect} from 'react';



import slid0 from "../assets/images/Rectangle 1.png"
import slid1 from "../assets/images/nslid/Group 37.png"
import slid2 from "../assets/images/nslid/Group 38.png"
import slid3 from "../assets/images/nslid/Group 39.png"
import slid4 from "../assets/images/nslid/Group 40.png"
import slid5 from "../assets/images/nslid/Group 41.png"
import slid6 from "../assets/images/nslid/Group 42.png"


import slid11 from "../assets/images/nslid1/Group 37.png"
import slid22 from "../assets/images/nslid1/Group 38.png"
import slid33 from "../assets/images/nslid1/Group 39.png"
import slid44 from "../assets/images/nslid1/Group 40.png"
import slid55 from "../assets/images/nslid1/Group 41.png"
import slid66 from "../assets/images/nslid1/Group 42.png"



function Slid() {
    const [lft, setlft] = useState("0px");
   
    const [stle, setstle] = useState("animprev");
    const [stle2, setstle2] = useState("animprev2");
 
    const divStyle = {
    //    width:"170px",
    //    marginLeft:"-5%",
        // backgroundColor: 'lightgray',
        left:lft
      };

const [imageSrc, setImageSrc] = useState(slid1);
const [imageSrc2, setImageSrc2] = useState(slid0);
const [imageSrc3, setImageSrc3] = useState(slid22);
  
// An array of image paths
const imagePaths = [slid1, slid2,slid3];
var imgsr=slid1; 
// Function to update the image source
var b = 1 ;
var stlecnt = true;
var stlecnt2 = true;
const updateImageSrc = () => {
  // Choose a random image path from the array
//   const randomIndex = Math.floor(Math.random() * imagePaths.length);
//   const randomImagePath = imagePaths[randomIndex];
// setlft("-240px")

if(stlecnt == true){

    setstle("animprev1")
    stlecnt = false
}
else{

    setstle("animprev")
    
    stlecnt = true

}


if(stlecnt2 == true){

    setstle2("animprev3")
    stlecnt2 = false
}
else{

    setstle2("animprev2")
    
    stlecnt2 = true

}
if(b==6){
    b=0;
}  
if(b == 0){
    setImageSrc(slid1);
    setImageSrc2(slid0);
    setImageSrc3(slid22);
    
}
    
if(b == 1){
    setImageSrc(slid2);
    setImageSrc2(slid11);
    setImageSrc3(slid33);
    
}
if(b == 2){
    setImageSrc(slid3);
    setImageSrc2(slid22);
    setImageSrc3(slid44);
     

}
if(b == 3){
    setImageSrc(slid4);
    setImageSrc2(slid33);
    setImageSrc3(slid55);

}
    
if(b == 4){
    setImageSrc(slid5);
    setImageSrc2(slid44);
    setImageSrc3(slid66);

}
if(b == 5){
    setImageSrc(slid6);
    setImageSrc2(slid55);
    setImageSrc3(slid11);


}
b++;
// setlft("0px")
    
};
  
// useEffect hook to update the image source every one second
useEffect(() => {
    setInterval(function slids(){
    // alert("hi")
    updateImageSrc();
  }, 5000);
//   return () => clearInterval(interval);
}, []); // Empty dependency array ensures that this effect runs only once on component mount
   
  return (
    <>
        <div className="prev">
        <img className={'islid '+stle} src={imageSrc2} alt="fasss" />
        
        </div>
         <img style={divStyle} className ={"slids "} id='sli' src={imageSrc} alt="fasss" />
        <div className="next">
        <img className='islid'  src={imageSrc3} alt="fasss" />
        
        </div>
    </>
  );
}

export default Slid;