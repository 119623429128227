
import React, { useEffect, useState } from 'react';


import Slid from "./slide.js"
import Ico from "./Ico.js"
import Progre from "./progre.js"


import Rotate from "./rotate.js"

import whatsapp from "../assets/images/Frame 16.png"
import transfer from "../assets/images/Group 21.png"
import logo from "../assets/images/log (2).gif"
import led from "../assets/images/Group 23.png"

import transfer1 from "../assets/images/trans/Frame 4.png"
import transfer2 from "../assets/images/trans/Group 20.png"
import transfer3 from "../assets/images/trans/t.png"


import slid1 from "../assets/images/transfer/Frame 5.png"
import slid2 from "../assets/images/transfer/Frame 7.png"
import slid3 from "../assets/images/transfer/Frame 8.png"
import slid4 from "../assets/images/transfer/Frame 9.png"
import slid5 from "../assets/images/transfer/Frame 10.png"
import slid6 from "../assets/images/transfer/Frame 11.png"






import cir1_img1 from "../assets/images/Ellipse 9.png"
import cir1_img2 from "../assets/images/Ellipse 13.png"
import cir1_img3 from "../assets/images/Group 1.png"
import cir1_img4 from "../assets/images/Group 2.png"
import cir1_img5 from "../assets/images/Group 4.png"
import cir1_img6 from "../assets/images/Group 7.png"
import cir1_img7 from "../assets/images/Group 8.png"
import cir3_img1 from "../assets/images/idea lamp with stars (1).png"
import cir2_img1 from "../assets/images/circle2/Ellipse 11.png"
import cir2_img2 from "../assets/images/circle2/Ellipse 12.png"
import cir2_img3 from "../assets/images/circle2/Frame 1.png"
import cir2_img4 from "../assets/images/circle2/Frame 2.png"
import cir2_img5 from "../assets/images/circle2/Group 3.png"
import cir2_img6 from "../assets/images/circle2/Group 5.png"
import cir2_img7 from "../assets/images/circle2/Group 10.png"


import "../assets/css/home.css"

function Home() {

    const style = [{
        '--i': 1, // Note the quotes around --i
      },
      {
        '--i': 2, // Note the quotes around --i
      },
      {
        '--i': 3, // Note the quotes around --i
      },
      {
        '--i': 4, // Note the quotes around --i
      },
      {
        '--i': 5, // Note the quotes around --i
      },
      {
        '--i': 6, // Note the quotes around --i
      },
      {
        '--i': 7, // Note the quotes around --i
      }
    ];

    var script = document.createElement('script');
    script.src = `${process.env.PUBLIC_URL}/js/progressbar.js`;
    script.async = true;
    document.body.appendChild(script);
        
    var script = document.createElement('script');
    script.src = `${process.env.PUBLIC_URL}/js/pgfold.js`;
    script.async = true;
    document.body.appendChild(script);

  return (
    
    <>

        <div className="cros">

        <div className = "container">
                <div className="icon">
                    <div id='k1'  className="k1 b imgBox" style={style[0]}>
                        <img id='k11' src={cir1_img1} alt="" />
                    
                    </div>

                    <div id='k2'  className="k2 b imgBox" style={style[1]}>
                        <img id='k22' src={cir1_img2} alt="" />
                    
                    </div>
                    <div id='k3'  className="k3 b imgBox" style={style[2]}>
                        <img id='k33' src={cir1_img3} alt="" />
                    
                    </div>

                    <div id='k4'  className="k4 b imgBox" style={style[3]}>
                        <img id='k44' src={cir1_img4} alt="" />
                    
                    </div>

                    <div id='k5'  className="k5 b imgBox" style={style[4]}>
                        <img id='k55' src={cir1_img5} alt="" />
                    
                    </div>

                    <div id='k6'  className="k6 b imgBox" style={style[5]}>
                        <img id='k66' src={cir1_img6} alt="" />
                    
                    </div>

                    <div id='k7' className="k7 b imgBox" style={style[6]}>
                        <img id='k77' src={cir1_img7} alt="" />
                    
                    </div>
                    <div id='k8' className="k7 b imgBox" style={style[6]}>
                    <p id='k88'>Product Designer</p>    
                  </div>
                  <div id='k9' className="k7 b imgBox" style={style[6]}>
                    <p id='k99'>Business Analyst</p>  
                  </div>

                </div>

            </div>

             <div className = "container cont2">
                   <Rotate/>
                </div>

                <div className = "container cont3">
                <div className="icon">
                    <div id='j1'  className=" b imgBox imgBox3" style={style[0]}>
                        <img id='j11' src={cir3_img1} alt="" />
                    
                    </div>

                  
                </div>

            </div> 

            <div className="containerT">

                  
                    <div className="load">
                    

                        <div className="slider">
                            {/* <img className ="slids" id='' src={slid1} alt="" /> */}
                            <Slid/>
                            {/* <img className ="slids" id='ji2' src={slid2} alt="" /> */}
                            {/* <img className ="slids" id='ji3' src={slid3} alt="" /> */}
                            {/* <img className ="slids" id='ji4' src={slid5} alt="" /> */}
                            {/* <img className ="slids" id='ji5' src={slid6} alt="" /> */}
                            {/* <img src={slid} alt="" />
                            <p>hi</p>

                            <p>dasfasdf</p> */}
                        </div>  
                          {/* <img src={led} alt="" /> */}
                           
                           <Progre/>

                    </div>

                    <div className="transferImg">
                        {/* <img src={transfer} alt="" /> */}
                        <img src={transfer1} alt="" />
                        <div className="pgbar">
                           <div className="pgk" id='pgfol'>
                                
                           </div>
                           <Ico/>
                        </div>
                        <img id='rIco' src={transfer3} alt="" />
                    </div>

                    <div className="logo">
                        <img src={logo} alt="" />
                    </div>
            </div>

            <div className="wt" >

              <a href="https://wa.me/+918655997455">
                <img src={whatsapp}   alt="" />
              </a>     
            </div>

            <div className="bottText">

                <h1>Exciting Updates on your way!</h1> 
            </div>

        </div>

    
    </>

  );
}

export default Home;
