import React, { useState ,useEffect} from 'react';

import cir2_img1 from "../assets/images/circle2/Ellipse 11.png"
import cir2_img2 from "../assets/images/circle2/Ellipse 12.png"
import cir2_img3 from "../assets/images/circle2/Frame 1.png"
import cir2_img4 from "../assets/images/circle2/Frame 2.png"
import cir2_img5 from "../assets/images/circle2/Group 3.png"
import cir2_img6 from "../assets/images/circle2/Group 5.png"
import cir2_img7 from "../assets/images/circle2/Group 10.png"



function Rotate() {
    
    const style = [{
        '--i': 1, // Note the quotes around --i
      },
      {
        '--i': 2, // Note the quotes around --i
      },
      {
        '--i': 3, // Note the quotes around --i
      },
      {
        '--i': 4, // Note the quotes around --i
      },
      {
        '--i': 5, // Note the quotes around --i
      },
      {
        '--i': 6, // Note the quotes around --i
      },
      {
        '--i': 7, // Note the quotes around --i
      }
    ];

    
    const [progr, setProgr] = useState(0);
 
    const divStyle = {
    //    transform:"rotate(0deg)",
    //    marginLeft:"-5%",
        // backgroundColor: 'lightgray',
     
      };

      var prg = 0 

function updateProgr(){

    if(prg == 300){
        setProgr(0)
        prg = 0
    }
    else{
        prg+=60
    setProgr(prg);

    }
}
// useEffect hook to update the image source every one second
useEffect(() => {
    setInterval(function slids(){
    // alert("hi")
    // updateProgr();
  }, 1000);
//   return () => clearInterval(interval);
}, []); // Empty dependency array ensures that this effect runs only once on component mount
   
  return (
    
    <div className="icon">
    <div id='l1' className=" b1 imgBox imgBox2" style={{...style[0],...divStyle}} >
        <img id='l11'  src={cir2_img1} alt=""  />
         {/* <p>Developer</p>            */}
    </div>

    <div id='l2' className=" b1 imgBox imgBox2" style={style[1]}>
        <img   id='l22' src={cir2_img2} alt="" />
                    
    </div>
                       

                        

    <div id='l3' className=" b1 imgBox imgBox2" style={style[3]}>
        <img id='l33' src={cir2_img5} alt="" />
                    
    </div>

    <div id='l4' className=" b1 imgBox imgBox2" style={style[4]}>
        <img id='l44' src={cir2_img6} alt="" />
                    
    </div>

                       

    <div id='l5'  className=" b1 imgBox imgBox2" style={style[6]}>
        <img id='l55' src={cir2_img7} alt="" />
                    
    </div>

    <div id='l6'  className=" b1 imgBox imgBox2" style={style[6]}>
      {/* <img id='i5' src={cir2_img7} alt="" /> */}
                 <h5 id='l66'>Figma</h5>   
  </div>
  <div id='l7'  className=" b1 imgBox imgBox2" style={style[6]}>
      {/* <img id='i5' src={cir2_img7} alt="" /> */}
                    <h5 id='l77'>Illustrator</h5>
  </div>

  <div id='l8'  className=" b1 imgBox imgBox2" style={style[6]}>
      {/* <img id='i5' src={cir2_img7} alt="" /> */}
                 <p id='l88'>Developer</p>   
  </div>
  <div id='l9'  className=" b1 imgBox imgBox2" style={style[6]}>
      {/* <img id='i5' src={cir2_img7} alt="" /> */}
                    <p id='l99'>Designer</p>
  </div>

</div>

  );
}

export default Rotate;