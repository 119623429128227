import React, { useState ,useEffect} from 'react';



function Progre() {
    const [progr, setProgr] = useState(0);
 
    const divStyle = {
       width:""+progr+"px",
    //    marginLeft:"-5%",
        // backgroundColor: 'lightgray',
     
      };

      var prg = 0 

function updateProgr(){

    if(prg == 240){
        setProgr(0)
        prg = 0
    }
    else{
        prg+=60
    setProgr(prg);

    }
}
// useEffect hook to update the image source every one second
useEffect(() => {
    setInterval(function slids(){
    // alert("hi")
    updateProgr();
  }, 1000);
//   return () => clearInterval(interval);
}, []); // Empty dependency array ensures that this effect runs only once on component mount
   
  return (
    <>
    
      <div className="progress" id='pg'>
    
      <div className="progress-bar" style  = {divStyle}id='pg-text'>
                                    
      </div>   
                        
      </div>
    
    </>
  );
}

export default Progre;